/**
 * This file kicks off VITEJS tasks to minify and compile CSS and JS.
 */
'use strict';

const flame = String.fromCodePoint(0x1f525)

// CSS imports
import './assets/scss/main.scss'

// JS imports
// Vendors
// import AOS from 'aos'
// AOS.init()

// Components
// import gsap from "gsap";
// import barba from '@barba/core';

// JS imports
import Alpine from 'alpinejs'
import intersect from '@alpinejs/intersect'
import focus from '@alpinejs/focus'
import validate from '@colinaut/alpinejs-plugin-simple-validate'
import './assets/js/_obaia-window-components'

Alpine.plugin(focus)
Alpine.plugin(intersect)
Alpine.plugin(validate)
Alpine.start()

// import { toggleMenuState, toggleMenuCurrentState } from './assets/js/_header'
// import { initPristine } from './assets/js/_validator-pristine'
import { initSwiper } from './assets/js/_swiper'
import './assets/js/_parallax'
// import { initObaiaPanorama } from './assets/js/_photosphere'

// function initSite() {
//
//     // AOS.init();
//
//     const directionTrackingUrls = ['', 'de-ontwerpen', 'over-mij', 'contact'];
//     let directionTracking;
//
//     // do something before the transition starts
//     barba.hooks.before((data) => {
//         // console.log(lightning + " ------ BEFORE");
//         setTimeout(function () {
//             document.documentElement.classList.add('is-transitioning');
//             let currentIndex = directionTrackingUrls.indexOf(data.current.namespace);
//             let nextIndex = directionTrackingUrls.indexOf(data.next.namespace);
//             if (nextIndex < currentIndex) {
//                 directionTracking = 'left';
//                 document.documentElement.classList.add('is-reversed');
//             } else {
//                 directionTracking = 'right';
//                 document.documentElement.classList.remove('is-reversed');
//             }
//         }, 200);
//         toggleMenuCurrentState();
//     });
//
//     // scroll to the top of the page
//     barba.hooks.enter((data) => {
//         // console.log(lightning + " ------ ENTER");
//         window.scrollTo(0, 0);
//         toggleMenuState('remove');
//         // AOS.init();
//     });
//
//     // do something after the transition finishes
//     barba.hooks.after(() => {
//         // console.log(lightning + " ------ AFTER");
//
//         document.documentElement.classList.remove('is-transitioning');
//     });
//
//     barba.init({
//         transitions: [{
//             name: 'opacity-transition',
//             leave(data) {
//                 // DEBUG
//                 return gsap.to(data.current.container, {
//                     opacity: 0
//                 });
//             },
//             enter(data) {
//                 // DEBUG
//                 return gsap.from(data.next.container, {
//                     opacity: 0
//                 });
//             }
//         }],
//         views: [
//             {
//                 namespace: 'home',
//                 beforeEnter(data) {
//                     initSwiper()
//                 }
//             },
//             {
//                 namespace: 'de-ontwerpen',
//                 beforeEnter(data) {
//                     initSwiper()
//                     initObaiaPanorama()
//                 }
//             }, {
//                 namespace: 'diensten',
//                 beforeEnter(data) {
//                     initSwiper()
//                 }
//             },
//             {
//                 namespace: 'contact',
//                 beforeEnter(data) {
//                     // initPristine()
//                     // ! kreeg deze meuk niet aan de praat
//                     // const gRecaptchaContainer = document.querySelector('input[name="g-recaptcha-response"]');
//                     // grecaptcha.ready(() => {
//                     //     console.log("STAP 2 - GCAPTCHA READY");
//                     //     grecaptcha.execute(gRecaptchaContainer.getAttribute('data-sitekey'), {
//                     //         action: 'captcha_index'
//                     //     }).then((token) => {
//                     //         console.log(token);
//                     //         gRecaptchaContainer.value = token;
//                     //         // send token to your backend in order to check the captcha:
//                     //         // you will need to make a POST request with token and private key
//                     //         // see https://developers.google.com/recaptcha/docs/verify#api_request
//                     //     });
//                     // });
//                 },
//             }
//         ]
//     });
// }

window.addEventListener('load', (e) => {
    document.documentElement.classList.add('is-loaded');
    // initSite();
}, { passive: true });

// END OF FILE
console.log(" Made with a keyboard by obaia " + flame)