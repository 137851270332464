// core version + navigation, pagination modules:
// import Swiper, {Navigation, Pagination, Mousewheel, EffectCreative, EffectFade, Controller} from 'swiper';
import Swiper from 'swiper'
import {Autoplay, EffectCreative, FreeMode, Mousewheel, Navigation, Pagination} from 'swiper/modules'

export function initSwiper() {
    // const swiperDiensten = new Swiper('#js-swiper-diensten', {
    //     modules: [Navigation, Pagination, Mousewheel, EffectFade, Controller],
    //     slidesPerView: 'auto',
    //     speed: 2000,
    //     // effect: 'fade',
    //     // fadeEffect: {
    //     //     crossFade: true
    //     // },
    //     effect: 'creative',
    //     creativeEffect: {
    //         // progressMultiplier: 0.8,
    //         limitProgress: 3,
    //         prev: {
    //             shadow: false,
    //             origin: "center center",
    //             translate: ["-80%", "0%", 0],
    //             rotate: [0, 0, 0],
    //             scale: 0.8
    //         },
    //         next: {
    //             shadow: false,
    //             origin: "center center",
    //             translate: ["80%", "0%", 0],
    //             rotate: [0, 0, 0],
    //             scale: 0.8
    //         },
    //     },
    //     mousewheel: {
    //         forceToAxis: true,
    //     },
    //     pagination: {
    //         el: '#js-swiper-diensten-pagination',
    //         type: "progressbar",
    //         clickable: true
    //     },
    //     navigation: {
    //         prevEl: '#js-swiper-diensten-button-prev',
    //         nextEl: '#js-swiper-diensten-button-next',
    //     },
    // })
    //
    // const swiperDienstenText = new Swiper('#js-swiper-diensten-text', {
    //     modules: [Navigation, Pagination, Mousewheel, EffectCreative, Controller],
    //     slidesPerView: 'auto',
    //     spaceBetween: 10,
    //     centeredSlides: true,
    //     speed: 2000,
    //     effect: 'creative',
    //     creativeEffect: {
    //         // progressMultiplier: 0.8,
    //         limitProgress: 3,
    //         prev: {
    //             shadow: false,
    //             origin: "center center",
    //             translate: ["-30%", "0%", -10],
    //             rotate: [0, 0, 0],
    //             scale: 0.8
    //         },
    //         next: {
    //             shadow: false,
    //             origin: "center center",
    //             translate: ["50%", "0%", -10],
    //             rotate: [0, 0, 0],
    //             scale: 1
    //         },
    //     },
    //     breakpoints: {
    //         0: {
    //             spaceBetween: 100,
    //             creativeEffect: {
    //                 prev: {
    //                     translate: ["-150%", "0%", -10],
    //                 },
    //                 next: {
    //                     translate: ["150%", "0%", -10],
    //                 }
    //             }
    //         },
    //         768: {
    //             creativeEffect: {
    //                 prev: {
    //                     translate: ["-80%", "0%", -10],
    //                 },
    //                 next: {
    //                     translate: ["80%", "0%", -10],
    //                 }
    //             }
    //         },
    //         1024: {
    //             creativeEffect: {
    //                 prev: {
    //                     translate: ["-30%", "0%", -10],
    //                 },
    //                 next: {
    //                     translate: ["50%", "0%", -10],
    //                 }
    //             }
    //         },
    //     },
    //     mousewheel: {
    //         forceToAxis: true,
    //     },
    //     on: {
    //         slideNextTransitionStart: (swiper) => {
    //             animateLamellen('next')
    //         },
    //         slidePrevTransitionStart: (swiper) => {
    //             animateLamellen('prev')
    //         },
    //     },
    // })
    //
    // swiperDiensten.controller.control = swiperDienstenText
    // swiperDienstenText.controller.control = swiperDiensten

    // function animateLamellen(direction) {
    //     const lamellen = document.querySelectorAll('.js-obaia-lamel')
    //     let animationEnter
    //     let animationLeave
    //     switch (direction) {
    //         case 'prev':
    //             console.log("PREV")
    //             animationEnter = 'right'
    //             animationLeave = 'left'
    //             break;
    //         case 'next':
    //             console.log("NEXT")
    //             animationEnter = 'left'
    //             animationLeave = 'right'
    //             break;
    //     }
    //     let loopReverse = false
    //     if (direction == 'prev') {
    //         loopReverse = true
    //     }
    //     let num = lamellen.length
    //     for (let i = 0, n = loopReverse ? num - 1 : 0, d = loopReverse ? -1 : 1; i < num; i++, n += d) {
    //         const element = lamellen[n]
    //         element.style.transformOrigin = `${animationEnter} center`
    //         setTimeout(function () {
    //             element.style.transform = 'scaleX(1)'
    //             setTimeout(function () {
    //                 element.style.transformOrigin = ''
    //                 element.style.transformOrigin = `${animationLeave} center`
    //                 element.style.transform = 'scaleX(0)'
    //             }, 1200)
    //         }, 100 * i)
    //     }
    // }

    const swiper = new Swiper('#js-swiper-deontwerpen', {
        modules: [Navigation, Pagination, Mousewheel, EffectCreative, FreeMode],
        freeMode: true,
        spaceBetween: 10,
        slidesPerView: 'auto',
        // effect: 'creative',
        // creativeEffect: {
        //     // progressMultiplier: 0.8,
        //     limitProgress: 3,
        //     prev: {
        //         shadow: false,
        //         origin: "center center",
        //         translate: ["-80%", "0%", 0],
        //         rotate: [0, 0, 0],
        //         scale: 0.8
        //     },
        //     next: {
        //         shadow: false,
        //         origin: "center center",
        //         translate: ["80%", "0%", 0],
        //         rotate: [0, 0, 0],
        //         scale: 0.8
        //     },
        // },
        mousewheel: {
            forceToAxis: true,
        },
        pagination: {
            el: '#js-swiper-deontwerpen-pagination',
            type: "progressbar",
            clickable: true
        },
        navigation: {
            prevEl: '#js-swiper-deontwerpen-button-prev',
            nextEl: '#js-swiper-deontwerpen-button-next',
        },
    })

    const swiperWerkwijze = new Swiper('#js-swiper-werkwijze', {
        // loop: true,
        // freeMode: true,
        spaceBetween: 10,
        mousewheel: {
            forceToAxis: true,
        },
        pagination: {
            el: '#js-swiper-werkwijze-pagination',
            // type: "progressbar",
            clickable: true,
            renderBullet: function (index, className) {
                return '<span class="' + className + ' text-xs w-8 h-8 inline-flex items-center justify-center">' + (index + 1) + '</span>';
            }
        }
    })
}

initSwiper()