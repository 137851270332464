
function castParallax() {
    // console.log("CASTING PARALLAX");
    const opThresh = 350;
    const opFactor = 750;
    let timer = null;
    const layers = document.getElementsByClassName("parallax");
    let startPos;
    let direction;
    // position every element from start
    for (var i = 0; i < layers.length; i++) {
        let layer = layers[i];
        startPos = JSON.parse(layer.getAttribute('data-paralax-pos'));
        layer.setAttribute('style', 'left:' + startPos.y + '%; top:' + startPos.x + 'px; z-index:' + startPos.z);
    }

    window.addEventListener("scroll", function (event) {
        var top = this.pageYOffset;
        // var layer, speed, yPos;
        let layer, speed, yPos;
        for (var i = 0; i < layers.length; i++) {
            layer = layers[i];
            speed = layer.getAttribute('data-paralax-speed');
            direction = (layer.getAttribute('data-paralax-direction') == 'down' ? '' : '-');
            yPos = direction + (top * speed / 100);
            // layer.setAttribute('style', 'transform: translate3d(0px, ' + yPos + 'px, 0px)');

            // KEVIN EDIT
            // let layer = layers[i];
            startPos = JSON.parse(layer.getAttribute('data-paralax-pos'));
            // console.log(startPos);
            // console.log(startPos.x);
            // console.log(startPos.y);
            // layer.setAttribute('style', 'top:' + startPos.x + 'px');
            // layer.setAttribute('style', 'left:' + startPos.y + '%');
            layer.setAttribute('style', 'left:' + startPos.y + '%; top:' + startPos.x + 'px; z-index:' + startPos.z + '; transform: translate3d(0px, ' + yPos + 'px, 0px)');
        }
        // if (top >= 20) {
        //     $(".sq_aquarium").addClass("scrolled");
        // } else {
        //     $(".sq_aquarium").removeClass("scrolled");
        // }
    });
}

// function dispelParallax() {
//     console.log("DISPELPARALLAX");
//     $("#nonparallax").css('display', 'block');
//     $("#parallax").css('display', 'none');
// }

// function castSmoothScroll() {
//     console.log("SMOOTHSCROLL");
//     $.srSmoothscroll({
//         step: 80,
//         speed: 300,
//         ease: 'linear'
//     });
// }

function startSite() {
    // console.log("STARTING SITE");
    var platform = navigator.platform.toLowerCase();
    var userAgent = navigator.userAgent.toLowerCase();
    // if (platform.indexOf('ipad') != -1 || platform.indexOf('iphone') != -1) {
    //     dispelParallax();
    // } else if (platform.indexOf('win32') != -1 || platform.indexOf('linux') != -1) {
    //     castParallax();
    //     if ($.browser.webkit) {
    //         castSmoothScroll();
    //     }
    // } else {
    //     castParallax();
    // }
    castParallax();
}
document.body.onload = startSite();